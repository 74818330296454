import { apiGetAuth, apiPutAuth, apiPostAuth } from '@/utils/api'
import { API_E_VOUCHER } from '@/utils/api-url'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'

const state = () => {
  return {
    list_client: { data: [] },
    list_order: { data: [] },
    list_voucher: { data: [] },
    detail_voucher: { data: [] },
    detail_order: {},
    progress_order: {},
    payment_verify: {},
    detail_client: {},
    progress_client: {},
    coop_client: {},
    pic_client: {},
    meta_filter: {},
    data_modal_add_edit: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false,
      code: null,
      limitUse: 0 
    }
  }
}

const mutations = {
  SET_BULK_VOUCHER (state, payload) {
    state.list_bulk_voucher = payload
  },
  SET_CLIENT (state, payload) {
    state.list_client = payload
  },
  SET_ORDER (state, payload) {
    state.list_order = payload
  },
  SET_DETAIL_ORDER(state, payload) {
    state.detail_order = payload
  },
  SET_PROGRESS_ORDER (state, payload) {
    state.progress_order = payload
  },
  SET_VOUCHER (state, payload) {
    state.list_voucher = payload
  },
  SET_DETAIL_VOUCHER (state, payload) {
    state.detail_voucher = payload
  },
  SET_PAYMENT_VERIFY (state, payload) {
    state.payment_verify = payload
  },
  SET_DETAIL_CLIENT (state, payload) {
    state.detail_client = payload
  },
  SET_PROGRESS_CLIENT (state, payload) {
    state.progress_client = payload
  },
  SET_COOP_CLIENT (state, payload) {
    state.coop_client = payload
  },
  SET_PIC_CLIENT (state, payload) {
    state.pic_client = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListClient (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_CLIENT_E_VOUCHER, payload)
      context.commit('SET_CLIENT', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetailClient (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.DETAIL_CLIENT_E_VOUCHER(payload))
      
      context.commit('SET_DETAIL_CLIENT', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getProgressClient (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.PROGRESS_CLIENT_E_VOUCHER(payload))
      
      context.commit('SET_PROGRESS_CLIENT', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getCooperationPeriod (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.COOP_CLIENT_E_VOUCHER(payload))
      
      context.commit('SET_COOP_CLIENT', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getPic (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.PIC_CLIENT_E_VOUCHER(payload))
      
      context.commit('SET_PIC_CLIENT', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async processPic(context, payload) {
    try {
      const response = await apiPutAuth(API_E_VOUCHER.PROCESS_CLIENT_E_VOUCHER(payload))
      
      notificationSuccess('PIC processing successful')
      
      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async rejectPic(context, payload) {
    try {
      const response = await apiPutAuth(
        `${API_E_VOUCHER.REJECT_CLIENT_E_VOUCHER}/${payload.id}`,
        { reason: payload.reason }
      )
      
      notificationSuccess('PIC rejection successful')

      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async approvePic(context, payload) {
    try {
      const response = await apiPostAuth(API_E_VOUCHER.APPROVE_CLIENT_E_VOUCHER, payload)
      
      notificationSuccess('PIC Approval successful')

      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async addNewPeriod(context, payload) {
    try {
      const response = await apiPostAuth(API_E_VOUCHER.COOPERATION_E_VOUCHER, payload)
      
      notificationSuccess('Add Period successful')

      return response
    } catch (error) {
      notificationDanger(error)
    }
  },
  async updateClient (context, payload) {
    try{
      const response = await apiPutAuth(API_E_VOUCHER.CLIENT_E_VOUCHER + '?code=' + payload.code, {release_amount: payload.release_amount})
      notificationSuccess('Edit Data Successful')
      return response
    }catch (error){
      notificationDanger(error)
    }
  },

  // Voucher Management
  async getListVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_ADD_VOUCHER_MANAGEMENT, payload)
      context.commit('SET_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetailVoucher (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.DETAIL_UPDATE_VOUCHER_MANAGEMENT(payload))
      context.commit('SET_DETAIL_VOUCHER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async createVoucher (context, payload) {
    try{
      const response = await apiPostAuth(API_E_VOUCHER.LIST_ADD_VOUCHER_MANAGEMENT, payload)
      return response.data
    }catch (error){
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateVoucher (context, payload) {
    try{
      const response = await apiPutAuth(API_E_VOUCHER.DETAIL_UPDATE_VOUCHER_MANAGEMENT(payload.id), payload.reqBody)
      return response.data
    }catch (error){
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async getListOrder (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.LIST_ORDER_E_VOUCHER, payload)
      context.commit('SET_ORDER', response.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getDetailOrder (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.DETAIL_ORDER_E_VOUCHER(payload))
      
      context.commit('SET_DETAIL_ORDER', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getProgressOrder (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.PROGRESS_ORDER_E_VOUCHER(payload))
      
      context.commit('SET_PROGRESS_ORDER', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async getPaymentVerify (context, payload) {
    try{
      const response = await apiGetAuth(API_E_VOUCHER.GET_PAYMENT_VERIFY_E_VOUCHER(payload))
      
      context.commit('SET_PAYMENT_VERIFY', response.data.data)
    }catch (error){
      notificationDanger(error)
    }
  },
  async paymentVerify (context, payload) {
    try{
      const response = await apiPostAuth(API_E_VOUCHER.PAYMENT_VERIFY_E_VOUCHER, payload)
      
      if (response.code != 400) {
        notificationSuccess('Verify Payment Success')
      }

      return response
    }catch (error){
      notificationDanger(error)
      return error.response.data
    }
  },
  async paymentDateVerify (context, payload) {
    try {
      const response = await apiPutAuth(
        `${API_E_VOUCHER.PAYMENT_DATE_VERIFY_E_VOUCHER}/${payload.id}`,
        { 
          reason: payload.reason
        }
      )

      if (response.code != 400) {
        notificationSuccess('Verify Payment Success')
      }

      return response
    } catch (error) {
      notificationDanger(error)
    }
  }, 
  async paymentReject (context, payload) {
    
    try{
      const response = await apiPostAuth(API_E_VOUCHER.PAYMENT_REJECT_E_VOUCHER, payload)
      
      if (response.code != 400) {
        notificationSuccess('Reject Order Success')
      }

      return response
    }catch (error){
      notificationDanger(error)
      return error.response.data
    }
  },
  async releaseVoucherOrder (context, payload) {
    
    try {
      const response = await apiPutAuth(
        `${API_E_VOUCHER.RELEASE_E_VOUCHER}/${payload.id}`
      )

      if (response.code != 400) {
        notificationSuccess('Release Voucher Success')
      }

      return response
    } catch (error) {
      notificationDanger(error)
      return error.response.data
    }
  }
}

const getters = {
  list_bulk_voucher (state) {
    return state.list_bulk_voucher
  },
  list_client (state) {
    return state.list_client
  },
  list_order (state) {
    return state.list_order
  },
  detail_order (state) {
    return state.detail_order
  },
  progress_order (state) {
    return state.progress_order
  },
  payment_verify (state) {
    return state.payment_verify
  },
  list_voucher (state) {
    return state.list_voucher
  },
  detail_voucher (state) {
    return state.detail_voucher
  },
  detail_client (state) {
    return state.detail_client
  },
  progress_client (state) {
    return state.progress_client
  },
  coop_client (state) {
    return state.coop_client
  },
  pic_client (state) {
    return state.pic_client
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
