const METHOD = {
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

const PREFIX_IMAGE = '/images'
const PREFIX_DOCUMENT = '/document'
const PREFIX_TEMPLATE_PRODUCT_MANAGEMENT = process.env.VUE_APP_API_URL_STORE+'/public/uploads/products/template-product-management.xlsx'

const CONSUMER_DEVICE_TYPE = [
  {value: 'ios', name: "IOS"},
  {value: 'android', name: "Android"}
]
const TRANSACTION_TYPE_LIST = [
  {value: '1', name: 'Offline'},
  {value: '2', name: 'Online'}
]
const LOGIN_ATTEMP_ACTION = [
  {id: 2, name: 'Login Success', value: '0'},
  {id: 3, name: 'Locked Out User', value: '1'},
  {id: 4, name: 'Password Reset From Not User Exist', value: '2'},
  {id: 5, name: 'Wrong Password', value: '3'},
  {id: 6, name: 'User Not Exist', value: '4'}
]
const STATUS_STAMP_REWARD = [
  {value: "", name: "All"},
  {value: "0", name: "Inactive"},
  {value: "1", name: "Active"},
  {value: "2", name: "Draft"},
  {value: "3", name: "In Progress"},
  {value: "4", name: "Failed"}
]
const STATUS_SKIPP_POOLING_SURVEY = [
  {value: "1", name: "Yes"},
  {value: "0", name: "No"}
]
const STATUS_TYPE_POOLING_SURVEY = [
  {value: "1", name: "Single choice"},
  {value: "2", name: "Multiple choice"},
  {value: "3", name: "Scale"},
  {value: "4", name: "Text response"},
  {value: "5", name: "Long text response"}
]
const STATUS = [
  {value: "", name: "All"},
  {value: "2", name: "Draft"},
  {value: "1", name: "Active"},
  {value: "0", name: "Inactive"}
]
const STATUS_COUPON = [
  {value: "", name: "All"},
  {value: "1", name: "Active"},
  {value: "2", name: "Cancelled"},
  {value: "3", name: "Expired"},
  {value: "4", name: "Used"}
]

const STATUS_EXTRA_STAMP_TYPE = [
  {value: '0', name: 'No Extra'},
  {value: '1', name: 'Multiplied'},
  {value: '2', name: 'Per Stamp Unit'}
]

const STATUS_STAMP_HOW_TO_EARN = [
  {value: "", name: "All"},
  {value: "1", name: "Minimal Total Transaksi"},
  {value: "2", name: "Pemebelian Produk Sponsor"},
  {value: "3", name: "Kombinasi"}
]

const STATUS_STAMP = [
  {value: "", name: "All"},
  {value: "10,30", name: "Stamp In"},
  {value: "20,21,31", name: "Stamp Out"}
]

const STATUS_OFFER = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Published - Live"},
  {value: "2", name: "Expired"},
  {value: "3", name: "Inactive"},
  {value: "4", name: "Published - Upcoming"}
]

const STATUS_BANNER = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Published - Live"},
  // {value: "2", name: "Archived"},
  {value: "3", name: "Expired"},
  {value: "4", name: "Inactive"},
  {value: "5", name: "Published - Upcoming"}
]

const STATUS_PUSHMESSAGE = [
  {value: "", name: "All"},
  {value: "0", name: "Draft"},
  {value: "1", name: "Pending Approval"},
  {value: "2", name: "Published - Upcoming"},
  {value: "3", name: "Published - Live"},
  {value: "4", name: "Expired"},
  {value: "5", name: "Rejected"},
  {value: "6", name: "Inactive"}
]

const TYPE_OFFER = [
  {value: "0", name: "All Member"},
  {value: "1", name: "App Member"}
]

const TYPE_BANNER = [
  {value: "", name: "All"},
  {value: "0", name: "Promo Item"},
  {value: "1", name: "Promo Catalog"},
  {value: "2", name: "Recipe Text"},
  {value: "3", name: "Recipe Image"},
  {value: "4", name: "Article Text"},
  {value: "5", name: "Article Image"},
  {value: "6", name: "General"},
  {value: "7", name: "Banner Promo"}
]

const TYPE_PUSHMESSAGE = [
  {value: "", name: "All"},
  {value: "0", name: "Broadcast"},
  {value: "1", name: "Recurring"}
]

const TYPE_INTERNAL_PAGE = [
  {value: "homepage", name: "Home Page"},
  {value: "articlepage", name: "Article Page"},
  {value: "benefitpage", name: "Benefit Page"},
  {value: "recipepage", name: "Recipe Page"},
  {value: "belanjapage", name: "Belanja Page"},
  {value: "detailbannerpage", name: "Detail Banner"},
  {value: "categorypage", name: "Category"}
]

const POSITION_HIGHLIGHT = [
  {value: "1", name: "1"},
  {value: "2", name: "2"},
  {value: "3", name: "3"},
  {value: "4", name: "4"},
  {value: "5", name: "5"},
  {value: "6", name: "6"},
  {value: "7", name: "7"},
  {value: "8", name: "8"},
  {value: "9", name: "9"},
  {value: "10", name: "10"}
]

const CATEGORY_SORT_BANNER = [
  {value: "1", name: "PERISHABLE"},
  {value: "2", name: "FOOD"},
  {value: "3", name: "NON FOOD"},
  {value: "4", name: "GENERAL MERCHANDISING"},
  {value: "5", name: "COUNTER AND PROMOTION"},
  {value: "6", name: "EQUIPMENT"},
  {value: "7", name: "INDRIRECT PROCUREMENT"}
]

const SEGMENTED_VOUCHER = [
  {value: "1", name: "Targeted"},
  {value: "2", name: "Non-Targeted"}
]

const TYPE_VOUCHER = [
  {value: "1", name: "Delivery"},
  {value: "2", name: "Order"},
  {value: "3", name: "By PLU"}
]

const TYPE_VOUCHER_DIGITAL = [
  {value: "regular", name: "Regular"},
  {value: "seasonal", name: "Seasonal"}
]

const STATUS_ORDER_VOUCHER_DIGITAL = [
  {values: "waiting_for_payment", name: "Waiting for Payment"},
  {values: "on_payment_verification", name: "On Payment Verification"},
  {values: "on_release_process", name: "On Release Process"},
  {values: "done", name: "Done"},
  {values: "payment_rejected", name: "Payment Rejected"}
]

const RULES_VOUCHER = [
  {value: "1", name: "Percentage"},
  {value: "2", name: "Amount"}
]

const STATUS_VOUCHER = [
  {value: "0", name: "No"},
  {value: "1", name: "Yes"}
]

const GET_TYPE_VOUCHER = {
  1: "Delivery",
  2: "Order",
  3: "By PLU"
}

const DELIVERY_TYPE = [
  {value: "1", name: "3PL"},
  {value: "2", name: "Internal"}
]

const IS_SUBSIDI = [
  {value: "0", name: "No"},
  {value: "1", name: "Yes"}
]

const FREE_DELIVERY = [
  {value: "0", name: "No"},
  {value: "1", name: "Yes"}
]

const IS_ACTIVE = [
  {value: "0", name: "No"},
  {value: "1", name: "Yes"}
]

const TYPE_PLANOGRAM = [
  {value: "0", name: "Homepage"},
  {value: "1", name: "Belanja"},
  {value: "2", name: "Homepage - Belanja"}
]

const STATUS_ORDER = {
  "WAITING_FOR_PAYMENT": 100,
  "EXPIRED": 110,
  "FAILED": 111,
  "PAID": 200,
  "STOCK_CHECKED": 201,
  "CANCEL": 210,
  "PICK_CONFIRMED_READY_TO_DELIVERY": 300,
  "PICK_CONFIRMED_READY_TO_PICKUP": 301,
  "DELIVERY": 310,
  "DISTRIBUTE_TO_CUSTOMER": 400,
  "DELIVERY_INCOMPLETE": 410
}

const LIST_ORDER_STATUS = [
  {id: 100, label: 'Waiting for Payment'},
  {id: 110, label: 'Payment Expired'},
  {id: 111, label: 'Payment Failed'},
  {id: 200, label: 'Paid'},
  {id: 210, label: 'Cancel'},
  {id: 201, label: 'Stock Checked'},
  {id: 300, label: 'Pick Confirmed & Ready to Delivery'},
  {id: 301, label: 'Pick Confirmed & Ready to Pick Up'},
  {id: 310, label: 'Delivery'},
  {id: 410, label: 'Delivery Incomplete'},
  {id: 400, label: 'Distribute to Customer'}
]

const PLACEMENT_TYPE = [
  {value: 1, name: "Promo Offline"},
  {value: 2, name: "Promo Online"}
]

const EXTRA_COUPON_TYPE = [
  {value: '1', name: 'Multiplied'},
  {value: '2', name: 'Unit'},
  {value: '0', name: 'No Extra'}
]

const EXTRA_COUPON_ARRAY = {
  0: 'No Extra',
  1: 'Multiplied',
  2: 'Unit'
}

const TRANSACTION_TYPE = {
  0: 'All',
  1: 'Offline',
  2: 'Online'
}

const RAFFLE_TRANSACTION_TYPE_LIST = [
  {value: '0', name: 'All'},
  {value: '1', name: 'Offline'},
  {value: '2', name: 'Online'}
]

const DESTROY_STATUS = {
  1: 'Failed',
  2: 'Success'
}

const DAYS_ARRAY = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

const COUPON_STATUS = {
  1: 'Active',
  2: 'Inactive',
  3: 'Inactive'
}

const DOWNLOAD_STATUS = {
  1: 'Export in progress',
  2: 'Ready to download'
}

const BANNER_TYPE = {
  1: 'Mini Banner',
  6: 'Long Banner',
  7: 'Main Banner',
  8: 'Artikel',
  9: 'Resep',
  10: 'Popup Banner'
}

const LIST_MONTH = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const PROVIDER = [
  {value: '', name: 'All'},
  {value: 'INDOSAT', name: 'INDOSAT'},
  {value: 'SMARTFREN', name: 'SMARTFREN'},
  {value: 'TELKOMSEL', name: 'TELKOMSEL'},
  {value: 'THREE', name: 'THREE'},
  {value: 'TRI HAPPY', name: 'TRI HAPPY'},
  {value: 'XL/AXIS', name: 'XL/AXIS'},
  {value: 'XL', name: 'XL'},
  {value: 'AXIS', name: 'AXIS'}
]

const TOPUP_BILLS_STATUS = [
  {value: '', name: 'All'},
  {value: 100, name: 'Waiting for Payment'},
  {value: 110, name: 'Payment Expired'},
  {value: 111, name: 'Payment Failed'},
  {value: 200, name: 'Payment successful'},
  {value: 250, name: 'Transaction is in Process'},
  {value: 251, name: 'Pending Operator Confirmation'},
  {value: 252, name: 'On-Hold'},
  {value: 451, name: 'Transaction Failed'},
  {value: 452, name: 'Transaction Failed by System'},
  {value: 453, name: 'Transaction Canceled'},
  {value: 400, name: 'Transaction successful'}
]

const TOPUP_BILLS_PRODUCT_TYPE = [
  {value: '0', name: 'All'},
  {value: '1', name: 'Pulsa'},
  {value: '2', name: 'Paket Data'}
]

const STATUS_BANNER_DIGITAL_PRODUCT= [
  {value: "", name: "All"},
  {value: "4", name: "Draft"},
  {value: "3", name: "Expired"},
  {value: "1", name: "Published - Live"},
  {value: "2", name: "Published - Upcoming"}
]

const BANNER_TYPE_DIGITAL_PRODUCT = [
  {value: '0', name: 'All'},
  {value: '1', name: 'Pulsa & Paket Data'}
]

const MONTH_BY_ID = {
  '01': 'jan',
  '02': 'feb',
  '03': 'mar',
  '04': 'apr',
  '05': 'may',
  '06': 'jun',
  '07': 'jul',
  '08': 'aug',
  '09': 'sep',
  '10': 'oct',
  '11': 'nov',
  '12': 'dec'
}

const MONTH_TYPE_LIST = [
  {value: '01', name: 'January'},
  {value: '02', name: 'February'},
  {value: '03', name: 'March'},
  {value: '04', name: 'April'},
  {value: '05', name: 'May'},
  {value: '06', name: 'June'},
  {value: '07', name: 'July'},
  {value: '08', name: 'August'},
  {value: '09', name: 'September'},
  {value: '10', name: 'October'},
  {value: '11', name: 'November'},
  {value: '12', name: 'December'}
]

const MOCK_FILTER_ROLE = {
  id: '1', 
  name: 'Offers', 
  view: true, 
  add: true, 
  edit: true,
  delete: true
}

const MOCK_USER_DATA = {
  name: 'John Doe',
  role: 'Administrator'
}

const MOCK_LIST_TEMPLATE_HC = {
  data: [
    {
      id:"1",
      category: 'Form1',
      title: 'Title',
      message_bahasa: 'message bahasa help center0',
      message_english: 'message english help center0'
    },
    {
      id:"2",
      category: 'Form2',
      title: 'Title',
      message_bahasa: 'message bahasa help center1',
      message_english: 'message english help center1'
    },
    {
      id:"3",
      category: 'Form3',
      title: 'Title',
      message_bahasa: 'message bahasa help center2',
      message_english: 'message english help center2'
    },
    {
      id:"4",
      category: 'Form3',
      title: 'Title',
      message_bahasa: 'message bahasa help center3',
      message_english: 'message english help center3'
    },
    {
      id:"5",
      category: 'Form4',
      title: 'Title',
      message_bahasa: 'message bahasa help center4',
      message_english: 'message english help center4'
    }
  ],
  meta: {
    page: 1,
    limit: 10,
    total: 10
  }
}

const MOCK_COUPON_HISTORY = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "MSI91123123",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "123123132",
      "name": "Set Of Kitchen",
      "redemption_date": "01/Jun/2023, 00:00:00",
      "coupon_status": "1",
      "store": "Jakarta"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "MSI91123123",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "123123132",
      "name": "Set Of Kitchen",
      "redemption_date": "01/Jun/2023, 00:00:00",
      "coupon_status": "1",
      "store": "Jakarta"
    }
  ]
}

const MOCK_POLLING_FORM = {
  "data": [
    {
      "id": "9ddaa462-168b-459f-a3e6-081c49ed30c2",
      "form_id": "001/23/08/2023",
      "name": "Test Survey Bahasa",
      "description": "Description Bahasa",
      "status": 1,
      "created_at": "2023-08-22T17:03:35.770436Z",
      "created_by": "420e85c0-41f0-4241-bce1-c8040357062f",
      "updated_at": "2023-08-22T17:03:35.770436Z",
      "updated_by": "420e85c0-41f0-4241-bce1-c8040357062f"
    }
  ],
  meta: {
    page: 1,
    limit: 10,
    total: 1
  }
}
const MOCK_POLLING_FORM_DETAIL = {
  "data": {
    "id": "e6b2388e-dea7-4802-95e3-343cd93311f5",
    "form_id": "001/29/08/2023",
    "name_bahasa": "Test Survey Bahasa",
    "name_english": "Test Survey English",
    "description_bahasa": "Description Bahasa",
    "description_english": "Description English",
    "image_bahasa": "form/2023-08-29/1693284167315547000-superindologo.jpeg",
    "image_english": "form/2023-08-29/1693284167761079000-superindologo.jpeg",
    "title_notification_bahasa": "Notif Bahasa",
    "title_notification_english": "Notif English",
    "message_notification_bahasa": "Message Bahasa",
    "message_notification_english": "Message English",
    "start_datetime": "2023-08-21 17:00:00",
    "end_datetime": "2023-08-25 18:00:00",
    "status": 1,
    "created_at": "2023-08-29T04:42:47.311103Z",
    "created_by": "725e4d9b-3c10-4738-b120-12dd6cdcee74",
    "updated_at": "2023-08-29T04:42:47.311104Z",
    "updated_by": "725e4d9b-3c10-4738-b120-12dd6cdcee74",
    "form_questions": [
      {
        "id": "bf02dde0-1dbc-4d99-8a66-781d253650b6",
        "question": "Test Question 1 Bahasa",
        "type": 1,
        "position": 2,
        "is_required": true,
        "answer": "[{\"option\":\"Question 2 Answer 1\",\"is_select\":false,\"is_other\":false},{\"option\":\"Question 2 Answer 2\",\"is_select\":false,\"is_other\":false}, {\"option\":\"label other Bahasa\",\"is_select\":false,\"is_other\":true}]",
        "language_type": 0
      },
      {
        "id": "bf02dde0-1dbc-4d99-8a66-781d253650b6",
        "question": "Test Question 1 Bahasa",
        "type": "1",
        "position": 4,
        "is_required": true,
        "answer": "[{\"option\":\"\",\"is_select\":false,\"is_other\":false},{\"option\":\"\",\"is_select\":false,\"is_other\":false}]",
        "language_type": 0
      },
      {
        "id": "bf02dde0-1dbc-4d99-8a66-781d253650b6",
        "question": "Test Question NULL Bahasa",
        "type": "",
        "position": 3,
        "is_required": false,
        "answer": "[]",
        "language_type": 0
      },
      {
        "id": "a8e5fcca-7449-4817-a9c9-97c31f112e59",
        "question": "Test Question 1 English",
        "type": 1,
        "position": 1,
        "is_required": false,
        "answer": "[{\"option\":\"tesss\",\"is_select\":false,\"is_other\":false},{\"option\":\"Answer 2 English\",\"is_select\":false,\"is_other\":false}]",
        "language_type": 1
      },
      {
        "id": "8ec16318-f587-49da-94df-bf134f5a70e4",
        "question": "Test Question 2 Bahasa",
        "type": 2,
        "position": 1,
        "is_required": false,
        "answer": "[{\"option\":\"Question 1 Answer 1\",\"is_select\":false,\"is_other\":false},{\"option\":\"Question 1 Answer 2\",\"is_select\":false,\"is_other\":false}]",
        "language_type": 0
      },
      {
        "id": "8ec16318-f587-49da-94df-bf134f5a70e4",
        "question": "Test Question 3 Bahasa",
        "type": 5,
        "position": 2,
        "is_required": false,
        "answer": "[{\"option\":\"long 1 Bahasa\",\"is_select\":false,\"is_other\":false}]",
        "language_type": 1
      }
    ],
    "form_tags": [
      {
        "tag_id": "fa9a36bf-713f-4fc8-9710-53904514d830",
        "tag": {
          "id": "fa9a36bf-713f-4fc8-9710-53904514d830",
          "type": 1,
          "name": "Jabodetabek (Jakarta, Bogor, Depok, Tangerang, Bekasi) & Palembang",
          "status": 1,
          "description": "",
          "group_tag": "Geographic",
          "subgroup_tag": "Region",
          "reference_code": "region_jakarta_palembang",
          "created_by": null,
          "updated_by": null,
          "deleted_by": "",
          "created_at": "2022-09-05T03:11:39.548182Z",
          "updated_at": "2022-09-05T03:11:39.548182Z",
          "deleted_at": null
        }
      }
    ]
  },
  "message": "OK"
}

const MOCK_STAMP_HISTORY = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "start_datetime": "01/Jun/2023, 00:00:00",
      "stamp_in": "2",
      "stamp_out": "19",
      "balance": "34",
      "transaction_id": "1213123213132",
      "coupon_id": "MSI91123123",
      "store": "Jakarta"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "start_datetime": "15/Feb/2023, 00:00:00",
      "stamp_in": "10",
      "stamp_out": "20",
      "balance": "50",
      "transaction_id": "90349535490",
      "coupon_id": "MSI911231239030945",
      "store": "Bekasi"
    }
  ]
}
const MOCK_TOP_PRODUCTS = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "product_sku": "562324234",
      "product_name": "01/Jun/2023, 00:00:00",
      "supplier": "2312321",
      "normal_price": "Set of kitchen",
      "coupon_amount": "Rizki irawan"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "product_sku": "562324234",
      "product_name": "01/Jun/2023, 00:00:00",
      "supplier": "2312321",
      "normal_price": "Set of kitchen",
      "coupon_amount": "Rizki irawan"
    }
  ]
}
const MOCK_DETAIL_COUPON = {
  "id":"afb41125-de88-4520-879d-cffcda1b9e42",
  "coupon_id": "562324234",
  "claimed_date": "01/Jun/2023, 00:00:00",
  "reward_id": "2312321",
  "name": "Set of kitchen",
  "consumer_name": "Rizki irawan",
  "phone_number": "028347234423",
  "email": "rizki@gmail.com",
  "app_card_id": "23312221231123",
  "physical_card_id": "23312221231123",
  "redemption_date": "01/Jun/2023, 23:59:59",
  "expiry_date": "01/Jun/2023, 23:59:59",
  "status": "0",
  "store": "Tebet"
}

const MOCK_COUPONS = 
{
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "562324234",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "2312321",
      "name": "Set of kitchen",
      "consumer_name": "Rizki irawan",
      "phone_number": "028347234423",
      "email": "rizki@gmail.com",
      "app_card_id": "23312221231123",
      "physical_card_id": "23312221231123",
      "redemption_date": "01/Jun/2023, 23:59:59",
      "status": "0",
      "store": "Tebet"
    },
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "coupon_id": "562324234",
      "claimed_date": "01/Jun/2023, 00:00:00",
      "reward_id": "2312321",
      "name": "Set of kitchen",
      "consumer_name": "Rizki irawan",
      "phone_number": "028347234423",
      "email": "rizki@gmail.com",
      "app_card_id": "23312221231123",
      "physical_card_id": "23312221231123",
      "redemption_date": "01/Jun/2023, 23:59:59",
      "status": "0",
      "store": "Tebet"
    }
  ]
}
const MOCK_HOWTO_REDEEMS = 
{
  "data": [
    {
      "title_bahasa": "My Super Indo App Update 1",
      "title_english": "My Super Indo App  Update"
    },
    {
      "content_bahasa": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
      "content_english": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>"
    }
  ],
  "message": "OK"
}
const MOCK_HOWTO_REDEEM = 
{
  "data": {
    "content_bahasa": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
    "content_english": "<p style=text-align:center;><strong>Syarat&nbsp;</p><strong>",
    "title_bahasa": "My Super Indo App Update 1",
    "title_english": "My Super Indo App  Update"
  },
  "message": "OK"
}
const MOCK_REWARD_DETAIL = {
  name: 'Kitchen',
  campaign_id: 'Campaign',
  status: '1',
  promo_code: 'TEST_DETAIL',
  supplier: 'Master Chef EDIT',
  product_reward_price: '30000',
  exchange_stamp: '20 EDIT',
  product_list: [{id: 1, product_name: 'set of kitchen'}, {id: 2, product_name: 'set of kitchen2'}],
  product_normal_price: '100000',
  start_datetime: "2023-06-12 04:38:03",
  end_datetime: "2023-06-22 04:38:03",
  start_datetime_coupon: "2023-06-12 04:38:03",
  end_datetime_coupon: "2023-06-22 04:38:03",
  reward_available: '1',
  reward_repeatable: '1',
  reward_repeatable_limit: '5',
  // number_of_repetition: '4',
  reward_tags: [
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "tag_id": "e3a20bc6-deff-43fa-a5b6-44abffd87b72",
      "tag": {
        "id": "e3a20bc6-deff-43fa-a5b6-44abffd87b72",
        "type": 0,
        "name": "Salman Test Tag",
        "status": 1
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "tag_id": "16607cb0-9155-4c3e-82a9-629dd3c275a0",
      "tag": {
        "id": "16607cb0-9155-4c3e-82a9-629dd3c275a0",
        "type": 1,
        "name": "Age Range 30-34",
        "status": 1
      }
    }
  ],
  reward_detail: [
    {title: 'bahasaINDO',language_type: 0,  description: 'DESCINDO', term: 'termINDO', image_upload: [
      'https://storage.googleapis.com/lsi-loyalty-qa/offer/1678351916409971042-HEAD%20AND%20SOULDERS.jpg',
      'https://storage.googleapis.com/lsi-loyalty-qa/offer/1670993660188423784-morinaca.jpeg', ''
    ]},
    {title: 'bahasaENGLISH', language_type: 1, description: 'DESCENGLISH', term: 'termENG', image_upload: ['https://storage.googleapis.com/lsi-loyalty-qa/offer/1678351916409971042-HEAD%20AND%20SOULDERS.jpg', '', '']}
  ],
  reward_stores: [
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "6",
      "store": {
        "id": "6",
        "name": "CINERE MALL"
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "15",
      "store": {
        "id": "15",
        "name": "DAGO"
      }
    },
    {
      "stamp_id": "428d5c3f-136e-452a-9e98-828682af6101",
      "store_id": "96",
      "store": {
        "id": "96",
        "name": "DUREN TIGA"
      }
    }
  ]
}
const MOCK_REWARD_PROMO = {
  data: {
    reward_type: '0',
    supplier: 'Master Chef',
    product_reward_price: '30000',
    product_list: [{id: 1, product_name: 'set of kitchen'}, {id: 2, product_name: 'set of kitchen2'}],
    reward_stores: "0008;0021;0119",
    product_normal_price: '100.000',
    start_datetime: '23/Jun/22, 00:00:00',
    end_datetime: '23/Jun/22, 23:59:59',
    reward_available: '1',
    reward_repeatable: '1',
    reward_repeatable_limit: '5',
    start_datetime_coupon: '23/Jun/22, 00:00:00',
    end_datetime_coupon: '23/Jun/22, 23:59:59'
  }
}
const MOCK_REWARD = {
  data: [
    {
      "id":"afb41125-de88-4520-879d-cffcda1b9e42",
      "reward_id": "003/03/05/2023",
      "name": "Set of Kitchen",
      "exchange_stamp": "20",
      "reward_price": "20000",
      "start_date": "01/Jun/2023, 00:00:00",
      "end_date": "01/Jun/2023, 23:59:59",
      "created_at": "2023-05-03T06:54:21.503926Z",
      "created_by": "452e2e75-1b87-4036-9d81-57b9ea33cf55",
      "last_updated": "2023-05-03T06:54:21.503926Z",
      "updated_by": "452e2e75-1b87-4036-9d81-57b9ea33cf55",
      "status": "0"
    }
  ],
  "meta": {
    "page": 0,
    "limit": 0,
    "total": 0
  }
}
const MOCK_DETAIL_STAMP_PROMO_MINIMUM = {
  name: "51.000 Stamp",
  promo_code: "MNM111",
  type: "1",
  status: "0",
  minimum_transaction: "50000",
  stamp_earn_minimum_transaction:"5000",
  stamp_multiply: "0",
  start_datetime: "01/Jun/2023, 00:00:00",
  end_datetime: "31/Jun/2023, 00:59:59",
  stamp_excluded_product: "1",
  extra_stamp_type: "2",
  extra_stamp_value: "2",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_DETAIL_STAMP_PROMO_SPONSOR = {
  name: "50.000 Stamp",
  promo_code: "SPN111",
  type: "2",
  status: "0",
  stamp_earn_sponsored_product: "22",
  start_datetime:"01/Jun/2023, 00:00:00",
  end_datetime:"31/Jun/2023, 00:59:59",
  stamp_excluded_product: "1",
  extra_stamp_type: "1",
  extra_stamp_value: "2",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_DETAIL_STAMP_PROMO_KOMBINASI = {
  name:"50.000 Stamp",
  promo_code: "KMB111",
  type: '3',
  status: "0",
  minimum_transaction: '50000',
  stamp_earn_minimum_transaction:"5000",
  stamp_multiply: '0',
  stamp_earn_sponsored_product: "51000",
  stamp_excluded_product: "1",
  start_datetime:"01/Jun/2023, 00:00:00",
  end_datetime:"31/Jun/2023, 00:59:59",
  extra_stamp_type: "0",
  extra_stamp_value: "0",
  stamp_sponsored_products: [
    {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
    {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
  ],
  consumer_tag: [
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd " },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asdasd" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC asda" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" },
    { "id": "1b4dfb1f-0bb6-4c0a-be2a-e16dd0d6aaed", "type": 0, "name": "NKC" }
  ],
  content_tag: [ 
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" },
    {"id": "23b933c9-62d1-4834-92f2-77987bfdea1e", "type": 1, "name": "Email Registration in the past 0 to 7 days" } 
  ],
  stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
    'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
    'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
}
const MOCK_STAMP_PROMO_MINIMUM = {
  data: {
    type: '1',
    minimum_transaction: '50000',
    stamp_multiply: '0',
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}
const MOCK_STAMP_PROMO_SPONSOR = {
  data: {
    type: '2',
    stamp_sponsored_products: [
      {product_code: 111, product_name: 'Sari Gandum Kelapa', product_price: 20000, product_supplier: 'Indofood', remark: 'Approved'},
      {product_code: 222, product_name: 'Oreo', product_price: 10000, product_supplier: 'Indofood', remark: 'Approved'},
      {product_code: 333, product_name: 'Nabatti', product_price: 3000, product_supplier: 'Indofood', remark: 'Approved' }
    ],
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}
const MOCK_STAMP_PROMO_KOMBINASI = {
  data: {
    type: '3',
    minimum_transaction: '50000',
    stamp_multiply: '1',
    stamp_sponsored_products: [
      {product_code: 111, product_name: 'Chitatos', product_price: 1500, product_supplier: 'Indofood'},
      {product_code: 222, product_name: 'Chiki Enak', product_price: 30000, product_supplier: 'Indofood'},
      {product_code: 333, product_name: 'Biskuit Roma', product_price: 40000, product_supplier: 'Indofood'}
    ],
    stamp_stores: ['Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 
      'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta', 'Kereweng', 'Purwakarta','Kereweng', 
      'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta','Kereweng', 'Purwakarta']
  }
}
const MOCK_RATINGS = {
  data: [
    {
      rating_id: '002/10/04/2023',
      transaction_id: '6523473246234872',
      transaction_date: '2023-10-15T06:51:37.560971Z',
      rating_date: '2023-10-16T06:51:37.560971Z',
      member_id: '00000111',
      consumer_name: 'Testing Name',
      phone_number: '085674747477',
      email: 'tes@gmail.com',
      type_response: 'Has Responded',
      rating: '4',
      feedback: 'Sangat menarik aplikasi ini',
      store: 'Super Indo Tebet'
    }
  ]
}
const MOCK_RATING = {
  data: {
    rating_id: '002/10/04/2023',
    transaction_id: '6523473246234872',
    transaction_date: '2023-10-15T06:51:37.560971Z',
    rating_date: '2023-10-16T06:51:37.560971Z',
    member_id: '00000111',
    consumer_name: 'Testing Name',
    phone_number: '085674747477',
    email: 'tes@gmail.com',
    type_response: 'Has Responded',
    rating: '4',
    feedback: 'Sangat menarik aplikasi ini',
    store: 'Super Indo Tebet'
  }
}
const MOCK_LOGIN_ATTEMPT_LIST = {
  data: [
    {
      date: '2023-10-15T06:51:37.560971Z',
      wrong_password: '123',
      not_exist_user: '124',
      locked_out_user: '125',
      password_reset: '126',
      wrong_password_percentage: '21.11',
      total_login_success: '1722',
      total_login_failed: '1221',
      total_login_attempt: '1220'
    }
  ]
}
const MOCK_LOGIN_ATTEMPT_DETAIL = {
  data: [
    {
      datetime: '2023-10-15T06:51:37.560971Z',
      name: 'sutan',
      email: 'sutan@gmail.com',
      details: 'Login Success'
    }
  ]
}

export {
  METHOD,
  PREFIX_IMAGE,
  STATUS_OFFER,
  STATUS_BANNER,
  STATUS_PUSHMESSAGE,
  TYPE_OFFER,
  TYPE_BANNER,
  TYPE_PUSHMESSAGE,
  PREFIX_DOCUMENT,
  TYPE_INTERNAL_PAGE,
  POSITION_HIGHLIGHT,
  CATEGORY_SORT_BANNER,
  MOCK_FILTER_ROLE,
  MOCK_USER_DATA,
  STATUS,
  MOCK_DETAIL_STAMP_PROMO_MINIMUM,
  MOCK_DETAIL_STAMP_PROMO_SPONSOR,
  MOCK_DETAIL_STAMP_PROMO_KOMBINASI,
  MOCK_STAMP_PROMO_MINIMUM,
  MOCK_STAMP_PROMO_SPONSOR,
  MOCK_STAMP_PROMO_KOMBINASI,
  MOCK_REWARD,
  MOCK_REWARD_PROMO,
  MOCK_REWARD_DETAIL,
  MOCK_HOWTO_REDEEM,
  MOCK_HOWTO_REDEEMS,
  MOCK_COUPONS,
  MOCK_TOP_PRODUCTS,
  MOCK_DETAIL_COUPON,
  STATUS_STAMP,
  STATUS_COUPON,
  MOCK_STAMP_HISTORY,
  MOCK_COUPON_HISTORY,
  STATUS_STAMP_HOW_TO_EARN,
  STATUS_EXTRA_STAMP_TYPE,
  SEGMENTED_VOUCHER,
  TYPE_VOUCHER,
  TYPE_VOUCHER_DIGITAL,
  STATUS_ORDER_VOUCHER_DIGITAL,
  GET_TYPE_VOUCHER,
  RULES_VOUCHER,
  STATUS_VOUCHER,
  DELIVERY_TYPE,
  IS_SUBSIDI,
  FREE_DELIVERY,
  IS_ACTIVE,
  TYPE_PLANOGRAM,
  STATUS_ORDER,
  LIST_ORDER_STATUS,
  PLACEMENT_TYPE,
  PREFIX_TEMPLATE_PRODUCT_MANAGEMENT,
  STATUS_SKIPP_POOLING_SURVEY,
  STATUS_TYPE_POOLING_SURVEY,
  MOCK_POLLING_FORM,
  MOCK_POLLING_FORM_DETAIL,
  MOCK_LIST_TEMPLATE_HC,
  MOCK_RATINGS,
  MOCK_RATING,
  MOCK_LOGIN_ATTEMPT_LIST,
  MOCK_LOGIN_ATTEMPT_DETAIL,
  LOGIN_ATTEMP_ACTION,
  STATUS_STAMP_REWARD,
  EXTRA_COUPON_TYPE,
  DESTROY_STATUS,
  TRANSACTION_TYPE_LIST,
  TRANSACTION_TYPE,
  CONSUMER_DEVICE_TYPE,
  RAFFLE_TRANSACTION_TYPE_LIST,
  EXTRA_COUPON_ARRAY,
  DAYS_ARRAY,
  COUPON_STATUS,
  DOWNLOAD_STATUS,
  LIST_MONTH,
  MONTH_BY_ID,
  MONTH_TYPE_LIST,
  BANNER_TYPE,
  PROVIDER,
  TOPUP_BILLS_STATUS,
  TOPUP_BILLS_PRODUCT_TYPE,
  STATUS_BANNER_DIGITAL_PRODUCT,
  BANNER_TYPE_DIGITAL_PRODUCT
}
